import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Prismic from 'prismic-javascript';
import { motion } from "framer-motion";
import {RichText} from 'prismic-reactjs';
import Modal from './Modal';
import Gallery from './Gallery.js';
import './Article.css';

class Article extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgLoaded: false,
      minTimeout: false,
      galleryView: true,
      contentOffset: null,
      processOffset: null
    }
    this.contentColumn = React.createRef();
    this.processImages = React.createRef();
  }

  componentDidMount() {
    const apiEndpoint = 'https://tom-chung-studio.cdn.prismic.io/api/v2';
    const client = Prismic.client(apiEndpoint)

    client.query(
      Prismic.Predicates.at('my.article.uid', this.props.uid)
    ).then(res => {
      this.setState({
        content: res.results[0].data
      });
      setTimeout(function() {
        this.setState({
          minTimeout: true,
        })
        this.props.minTimeout(true);

        window.addEventListener("resize", this.handleResize);
        this.handleResize();
      }.bind(this), 1200)
    });
    window.addEventListener("focus", this.playVideos);
    document.addEventListener("keydown", this.handleKeypress);
  }

  componentWillUnmount() {
    this.props.minTimeout(false);
    this.props.imgLoaded(false);
    this.props.onUnmount(false, false);
    window.removeEventListener("focus", this.playVideos);
    window.removeEventListener("resize", this.handleResize);
    document.addEventListener("keydown", this.handleKeypress);
  }

  imgLoaded = (bool) => {
    this.setState({
      imgLoaded: bool
    })
    this.props.imgLoaded(bool);
  }

  exit = () => {
    let buttons = document.querySelectorAll(".back a");
    buttons.forEach((button) => {
      button.classList.add("button-exit");
    });
  }

  handleKeypress = (event) => {
    if (event.keyCode === 27) {
      this.exit();
      this.props.history.push('/');
    } else if (event.keyCode === 39) {
      event.preventDefault()
      this.contentView();
    } else if (event.keyCode === 37) {
      event.preventDefault()
      this.galleryView();
    }
  }

  handleResize = () => {
    let contentHeight = this.contentColumn.current.clientHeight;
    let processHeight = this.processImages.current.clientHeight;
    let windowHeight = document.documentElement.clientHeight;

    this.setState({
      contentOffset: contentHeight > windowHeight ? (contentHeight - windowHeight) * -1 : null,
      processOffset: processHeight > windowHeight ? (processHeight - windowHeight) * -1 : null,
    });

    this.layoutImages();
  }

  contentView = () => {
    if (window.innerWidth <= 540) { return }
    this.setState({
      galleryView: false
    });
  }

  galleryView = () => {
    if (window.innerWidth <= 540) { return }
    this.setState({
      galleryView: true
    });
  }

  averageSize = (width, height, idealArea) => {
    return Math.round(width * Math.sqrt(idealArea / (width * height)));
  }

  playVideos = () => {
    let videos = document.querySelectorAll('video');
    videos.forEach((video, index) => {
      video.play();
    });
  }

  layoutImages = () => {
    let image1 = document.querySelectorAll(".column.left .process-image")[0];
    let image2 = document.querySelectorAll(".column.right .process-image")[0];
    if (!image1 || !image2) { return }
    image2.style.paddingTop = (image1.offsetHeight / 2) + "px";
  }

  render() {
    if (this.state.content) {
      let urls = []
      this.state.content.gallery.map((image, index) => {
        urls.push(image.gallery_image.url);
        return urls
      })

      let style = {
        pointerEvents: this.state.imgLoaded && this.state.minTimeout ? "all" : "none"
      }

      let backButton = <motion.div
        className="back"
        animate={{ opacity: this.state.imgLoaded && this.state.minTimeout ? 1 : 0 }}
        style={style}>
        <NavLink
          onClick={this.exit}
          to={"/"}>
          <svg className="button" width="32px" height="32px" viewBox="0 0 32 32">
            <path d="M16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 Z M11.7573593,10.3431458 C11.366835,9.95262146 10.73367,9.95262146 10.3431458,10.3431458 C9.95262146,10.73367 9.95262146,11.366835 10.3431458,11.7573593 L10.3431458,11.7573593 L14.5852525,15.9992525 L10.3431458,20.2426407 L10.2599571,20.336848 C9.95493225,20.7291392 9.98266179,21.2963703 10.3431458,21.6568542 L10.3431458,21.6568542 L10.4373531,21.7400429 C10.8296443,22.0450677 11.3968754,22.0173382 11.7573593,21.6568542 L11.7573593,21.6568542 L15.9992525,17.4142525 L20.2426407,21.6568542 C20.633165,22.0473785 21.26633,22.0473785 21.6568542,21.6568542 C22.0473785,21.26633 22.0473785,20.633165 21.6568542,20.2426407 L21.6568542,20.2426407 L17.4142525,15.9992525 L21.6568542,11.7573593 L21.7400429,11.663152 C22.0450677,11.2708608 22.0173382,10.7036297 21.6568542,10.3431458 L21.6568542,10.3431458 L21.5626469,10.2599571 C21.1703557,9.95493225 20.6031246,9.98266179 20.2426407,10.3431458 L20.2426407,10.3431458 L15.9992525,14.5852525 Z"/>
          </svg>
        </NavLink>
      </motion.div>

      let leftColumn = [];
      let rightColumn = [];
      let singleColumn = [];
      this.state.content.process_gallery.forEach((image, index) => {
        if (!image.process_image.url && !image.process_video.url) { return };
        let scale;
        let sqpx;
        let node;
        if (window.innerWidth >= 1280) {
          sqpx = 60000
          scale = 2.5
        } else if (window.innerWidth <= 1024 && window.innerWidth > 540) {
          sqpx = 80000
          scale = 1.25
        } else if (window.innerWidth <= 540) {
          sqpx = 200000
          scale = 1
        } else {
          sqpx = 60000
          scale = 3.5
        }

        if (image.process_image.url && !image.process_video.url) {
          let maxWidth = this.averageSize(
            image.process_image.dimensions.width,
            image.process_image.dimensions.height,
            sqpx * (Math.pow((window.innerWidth / 1280), scale))
          );
          node = <div
          key={index}
          className="process-image">
            <div style={{ width: maxWidth }}>
              <div 
                style={{ 
                  paddingBottom: 
                    (image.process_image.dimensions.height / image.process_image.dimensions.width) * 100 + "%"}}
                className="image-wrap">
                <img
                  style={{ maxWidth: maxWidth }}
                  draggable="false"
                  alt={index}
                  src={image.process_image.url + "&fit=clip&w=2048&h=2048"}/>
              </div>
              <div className="caption">
                {RichText.render(image.process_caption)}
              </div>
            </div>
          </div>
        } else if (image.process_video.url && !image.process_image.url) {
          let maxWidth = this.averageSize(
            image.video_width,
            image.video_height,
            sqpx * (Math.pow((window.innerWidth / 1280), scale))
          );
          node = <div
          key={index}
          className="process-image">
            <div style={{ width: maxWidth }}>
              <div 
                style={{ 
                  paddingBottom: 
                    (image.video_height / image.video_width) * 100 + "%"}}
                className="image-wrap"
                dangerouslySetInnerHTML={{ __html: `
                  <video
                    loop
                    muted
                    autoplay
                    playsinline
                    src="${image.process_video.url}"/>
                `}}
                />
              <div className="caption">
                {RichText.render(image.process_caption)}
              </div>
            </div>
          </div>
          this.playVideos();
        } else {
          return
        }
        if (
          (window.innerWidth > 1024) ||
          (window.innerWidth <= 760 && window.innerWidth > 540)) {
          if (!!(index % 2)) {
            rightColumn.push(node);
          } else {
            leftColumn.push(node);
          }
        } else {
          singleColumn.push(node);
        }

        this.layoutImages();
      });

      return (
        <div className="article">
          <Modal>
            {backButton}
          </Modal>
          <motion.div
            className="page-wrap"
            animate={{
              opacity: this.state.imgLoaded && this.state.minTimeout ? 1 : 0,
              x: !this.state.galleryView ? "calc(-50% + var(--page-peak))" : 0
            }}
            transition={{ type: "spring", stiffness: 200, damping: 100 }}
            style={style}>
            {backButton}
            <Gallery
              enabled={this.state.galleryView}
              onClick={this.galleryView}
              imgLoaded={this.imgLoaded}
              title={RichText.asText(this.state.content.title) + ", " + this.state.content.year}
              images={urls}/>
            <div
              className={this.state.galleryView ? "content disabled" : "content"}
              onClick={this.contentView}>
              <div className="title">
                <div
                  ref={this.contentColumn}
                  style={{ top: this.state.contentOffset }}
                  className="sticky">
                  <h2>{RichText.asText(this.state.content.title)}</h2>
                  <div className="details">
                    {this.state.content.type}, {this.state.content.year}
                  </div>
                  {RichText.render(this.state.content.description)}
                </div>
              </div>
              <div 
                className="process-images"
                ref={this.processImages}
                style={{ top: this.state.processOffset }}>
                <div className="column left">{leftColumn}</div>
                <div className="column right">{rightColumn}</div>
                <div className="column single">{singleColumn}</div>
              </div>
            </div>
          </motion.div>
        </div>
      )
    }
    return (
      null
    )
  }
}

export default Article;