import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from "framer-motion";
import './Info.css';

class Info extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      date: new Date(),
    }
  }

  componentDidMount() {
    setTimeout(function() {
      this.setState({
        isReady: true
      });
      this.props.minTimeout(true);
      this.props.imgLoaded(true);
    }.bind(this), 1200)
  }

  componentWillUnmount() {
    this.props.minTimeout(false);
    this.props.onUnmount(false, false);
  }

  render() {
    let style = {
      pointerEvents: this.state.isReady ? "all" : "none"
    }

    let backButton = <motion.div
      className="back info-back"
      animate={{ opacity: this.state.isReady ? 1 : 0 }}
      style={style}>
      <NavLink
        onClick={this.exit}
        to={"/"}>
        <svg className="button" width="32px" height="32px" viewBox="0 0 32 32">
          <path d="M16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 Z M11.7573593,10.3431458 C11.366835,9.95262146 10.73367,9.95262146 10.3431458,10.3431458 C9.95262146,10.73367 9.95262146,11.366835 10.3431458,11.7573593 L10.3431458,11.7573593 L14.5852525,15.9992525 L10.3431458,20.2426407 L10.2599571,20.336848 C9.95493225,20.7291392 9.98266179,21.2963703 10.3431458,21.6568542 L10.3431458,21.6568542 L10.4373531,21.7400429 C10.8296443,22.0450677 11.3968754,22.0173382 11.7573593,21.6568542 L11.7573593,21.6568542 L15.9992525,17.4142525 L20.2426407,21.6568542 C20.633165,22.0473785 21.26633,22.0473785 21.6568542,21.6568542 C22.0473785,21.26633 22.0473785,20.633165 21.6568542,20.2426407 L21.6568542,20.2426407 L17.4142525,15.9992525 L21.6568542,11.7573593 L21.7400429,11.663152 C22.0450677,11.2708608 22.0173382,10.7036297 21.6568542,10.3431458 L21.6568542,10.3431458 L21.5626469,10.2599571 C21.1703557,9.95493225 20.6031246,9.98266179 20.2426407,10.3431458 L20.2426407,10.3431458 L15.9992525,14.5852525 Z"/>
        </svg>
      </NavLink>
    </motion.div>

    return (
      <div className="article">
        {backButton}
        <motion.div 
          className="info"
          animate={{
            opacity: this.state.isReady ? 1 : 0
          }}>
          <div className="info-section">
            <p className="justified-text all-caps">Furniture Products</p>
            <p className="justified-text all-caps">&amp; info@tomchung.net &amp;</p>
            <p className="justified-text all-caps">Lighting Interiors</p>
          </div>
          <div className="info-section">
            <p className="justified-text">Tom Chung, b. '<span className="numerals">90</span> Vancouver BC</p>
            <p className="justified-text">Canada, is an independent industrial designer</p>
            <p className="justified-text">who established his eponymous studio</p>
            <p className="justified-text">in <span className="numerals">2016</span>. The studio creates context</p>
            <p className="justified-text">driven industrial design pieces for domestic,</p>
            <p className="justified-text">institutional and public environments.</p>
          </div>
          <div className="info-section">
            <p className="justified-text">Set in Nuckle by <a href="http://heavyweight.cz" rel="noopener noreferrer" target="_blank">Heavyweight</a> <a href="http://heavyweight.cz" rel="noopener noreferrer" target="_blank">Foundry</a>.</p>
            <p className="justified-text">Identity and custom type by <a href="http://jackcurtis.info" rel="noopener noreferrer" target="_blank">Jack</a> <a href="http://jackcurtis.info" rel="noopener noreferrer" target="_blank">Curtis</a>.</p>
            <p className="justified-text">Website designed and coded by <a href="https://andychung.me" rel="noopener noreferrer" target="_blank">Andy</a> <a href="https://andychung.me" rel="noopener noreferrer" target="_blank">Chung</a>.</p>
          </div>
          <div className="info-section">
            <p className="justified-text">All content copyright of Tom Chung</p>
            <p className="justified-text">Studio unless otherwise noted.</p>
          </div>
          <div className="info-section">
            <p className="justified-text all-caps"><a href="https://www.instagram.com/tom__chung/" rel="noopener noreferrer" target="_blank">@tom__chung</a> ©<span className="numerals">{this.state.date.getFullYear()}</span></p>
          </div>
        </motion.div>
      </div>
    )
  }

}

export default Info;