import React, { Component } from 'react';
import './Gallery.css';

class Gallery extends Component {

  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      opacity: false
    }
    this.gallery = React.createRef();
  }

  componentDidMount() {
    this.props.images.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
    window.addEventListener("resize", this.setHeight);
    this.setHeight();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setHeight);
  }

  next = () => {
    const max = this.props.images.length
    this.setState({ index: (this.state.index === max - 1) ?
      0 : this.state.index + 1 })
  }

  previous = () => {
    const max = this.props.images.length
    this.setState({ index: (this.state.index === 0) ?
      max - 1 : this.state.index - 1 })
  }

  handleScroll = (event) => {
    let index = Math.round((event.target.scrollLeft / (event.target.scrollWidth - event.target.offsetWidth)) * (this.props.images.length -1))
    this.setState({ index: index })
  }

  setHeight = () => {
    this.setState({ height: window.innerHeight })
  }

  reveal = () => {
    this.setState({ opacity: true });
    this.props.imgLoaded(true);
  }

  render() {
    let style = {
      opacity: this.state.opacity ? 1 : 0
    }
    return (
      <div
        ref={this.gallery}
        style={{ height: this.state.height }}
        onClick={this.props.onClick}
        onScroll={this.handleScroll}
        className={this.props.enabled ? "full-width-gallery" : "full-width-gallery disabled"}>
        <div className="pager next" onClick={this.next}/>
        <div className="pager previous" onClick={this.previous}/>
        <div className="image-wrap" style={style}>
        {this.props.images.map((image, index) => {
          let imgStyle = {
            zIndex: this.state.index === index ? "100" : "0",
            visible: !this.props.enabled && this.state.index !== index  ? "hidden" : "visible"
          }
          return (
            <div
              key={index}
              style={imgStyle}>
              <img
                style={imgStyle}
                alt={index}
                key={index}
                onLoad={index === 1 ? this.reveal : null}
                src={image + "&fit=clip&w=2048&h=2048"}/>
            </div>
          )
        })}
        </div>
        <div className="dots">
        {this.props.images.map((image, index) => {
          return (
            <div 
              key={index} 
              style={{ backgroundColor: this.state.index === index ? "white" : "" }}
              className="dot"/>
           )
        })}
        </div>
      </div>
    )
  }
}

export default Gallery;