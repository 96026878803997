import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from "framer-motion";
import Prismic from 'prismic-javascript';
import './Row.css';
import './ProjectLink.css';

class ProjectLink extends Component {

  constructor(props) {
    super(props);
    this.state = {
      link: true
    }
  }

  componentDidMount() {
    const apiEndpoint = 'https://tom-chung-studio.cdn.prismic.io/api/v2';
    const client = Prismic.client(apiEndpoint)

    client.query(
      Prismic.Predicates.at('my.article.uid', this.props.uid)
    ).then(res => {
      this.setState({
        content: res.results[0]
      });
      if (!this.props.grid) {
        this.reveal();
      }
    })
  }

  handleClick = (event) => {
    if (!this.state.link) event.preventDefault();
    if (!this.state.link) return;
    this.props.onPan(this.state.content.data.title[0].text, false)
  }

  onPan = (event, info) => {
    this.setState({ link: false });
    this.props.onPan(this.state.content.data.title[0].text, true);
  }

  onPanEnd = (event, info) => {
    let date = new Date();
    let string = "Tom Chung Studio ©" + date.getFullYear();
    setTimeout(function() {
      this.setState({ link: true });
    }.bind(this), 1);
    this.props.onPan(string, false);
  }

  reveal = () => {
    this.setState({
      loaded: true,
    });
  }

  render() {
    if (this.state.content) {
      if (this.props.grid) {
        let width = this.state.content.data.thumbnail.dimensions.width;
        let height = this.state.content.data.thumbnail.dimensions.height;
        let idealArea = 15000 * (window.innerWidth / 1280);
        let maxWidth = Math.round(width * Math.sqrt(idealArea / (width * height)));

        return (
          <motion.div
            className="spiralWrap"
            animate={{ opacity: this.state.loaded && !this.props.pageLoaded ? 1 : 0 }}
            transition={{
              delay: this.props.pageLoaded ? 0 : (Math.random() / 3) + 1
            }}
            style={{
                top: this.props.y,
                left: this.props.x,
                x: "-50%",
                y: "-50%"
              }}
            >
            <motion.div
              className={this.state.link ? "spiral" : "spiral dragging"}
              drag
              dragElastic={1.0}
              dragConstraints={this.props.bounds}
              onPan={this.onPan}
              onPanEnd={this.onPanEnd}
              transition={{ type: "spring", stiffness: 200, damping: 100 }}>
                <NavLink
                  onClick={this.handleClick}
                  to={`/projects/${this.props.uid}`}>
                  <img
                    onLoad={this.reveal}
                    draggable={false}
                    style={{ maxWidth: maxWidth }}
                    alt={this.state.content.data.title[0].text}
                    src={this.state.content.data.thumbnail.url + "&fit=clip&w=400&h=400"}/>
                </NavLink>
            </motion.div>
          </motion.div>
        )
      } else {
          return (
            <motion.div
              className="row-container"
              animate={{ opacity: this.state.loaded && !this.props.pageLoaded ? 1 : 0 }}
              transition={{
                delay: this.props.pageLoaded ? 0 : 1
              }}
              style={{
                x: 0,
                y: 0
              }}>
              <NavLink
                draggable={false}
                onClick={this.handleClick}
                className="row"
                to={`/projects/${this.props.uid}`}>
                <div className="title">
                  <span>{this.state.content.data.title[0].text}</span>
                </div>
                <div className="type">
                  <span>{this.state.content.data.type}</span>
                </div>
                <div className="client">
                  <span>{this.state.content.data.client}</span>
                </div>
                <div className="year"><span>{this.state.content.data.year}</span></div>
              </NavLink>
            </motion.div>
          )
        }
    } else {
      return null
    }
  }
}

export default ProjectLink;