import React, { Component } from 'react';
import { Route, withRouter, Switch, NavLink } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Prismic from 'prismic-javascript';
import { motion } from "framer-motion";
import Bounds from './Bounds.js';
import Article from './Article.js';
import Info from './Info.js';
import './App.css';
import './Fonts/Fonts.css';

const apiEndpoint = "https://tom-chung-studio.cdn.prismic.io/api/v2";
const client = Prismic.client(apiEndpoint)
const date = new Date();
const title = "Tom Chung Studio ©" + date.getFullYear();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoaded: false,
      pageTitle: title,
      blink: false,
      minTimeout: false,
      imgLoaded: false,
      isTouch: false,
      transition: false,
      grid:
        (localStorage.getItem("grid") === "true") ||
        !(localStorage.getItem("grid")) ? true : false
    }
    this.spiralWrap = React.createRef();
    this.spiral = React.createRef();
  }

  componentDidMount() {
    client.query(
      [Prismic.Predicates.at("document.type", "homepage")], { pageSize: 100 }
    ).then(response => {
      if (response) {
        this.setState({ projects: response.results[0].data.index });
      } else {
        this.setState({ notFound: !response });
      }
    });

    this.setState({
      isTouch: this.isTouch()
    })
  }

  isLocalStorageNameSupported = () => {
    let testKey = 'test', storage = window.sessionStorage;
    try {
      storage.setItem(testKey, '1');
      storage.removeItem(testKey);
      return true;
    } catch (error) {
      return false;
    }
  }

  isTouch = () => {
    var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    var mq = function (query) {
        return window.matchMedia(query).matches;
    }
    if ('ontouchstart' in window) {
        return true;
    }
    var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
  }

  setTitle = (string, blink) => {
    this.setState({
      pageTitle: string ? string : title,
      blink: blink
    });
  }

  setSubPageTimeout = (bool) => {
    this.setState({
      minTimeout: bool,
    });
  }

  setSubPageImgLoaded = (bool) => {
    this.setState({
      imgLoaded: bool,
    });
  }

  toggleGrid = () => {
    this.setState({
      transition: true
    });

    setTimeout(function() {
      this.setState(prevState => ({
        grid: !prevState.grid,
        transition: false
      }));
      if (this.isLocalStorageNameSupported()) {
        localStorage.setItem("grid", this.state.grid);
      }
    }.bind(this), 400);
  }

  render() {
    if (this.state.projects) {
      return (
        <div
          id="container"
          className={[
            this.state.isTouch ? "touch" : "no-touch",
            this.state.transition ? "transition" : "",
            this.state.grid ? "grid" : "list"].filter(e => !!e).join(' ')}>
          <div
            id="homepage"
            className={this.props.location.pathname !== "/" ? "hidden" : ""}>
            <motion.div
              className="navigation"
              animate={{ opacity: this.props.location.pathname !== "/" ? 0 : 1 }}
              transition={{
                delay: this.props.location.pathname !== "/" ? 0 : (Math.random() / 3) + 1
              }}>
              <NavLink draggable="false" to="/info">Info</NavLink>
              <a
                className="view-toggle"
                draggable="false"
                onClick={this.toggleGrid}>
                <span
                  style={{
                    order: this.state.grid ? "1" : "2"
                  }}>
                  List
                </span>
                <span
                  style={{
                    order: this.state.grid ? "2" : "1"
                  }}>
                  Grid
                </span>
              </a>
            </motion.div>
            <motion.h1
              animate={{ opacity: this.state.imgLoaded && this.state.minTimeout ? 0 : 1 }}
              className={this.state.blink ? "blink" : ""}>
                {this.state.pageTitle}
            </motion.h1>
            <svg id="spiral-template" ref={this.spiralWrap} viewBox="0 0 374 412" preserveAspectRatio="none">
              <path
                ref={this.spiral}
                id="spiral" d="M270.056 225.999L271.284 241.58L268.552 257.699L261.692 273.328L250.802 287.396L236.264 298.856L218.729 306.76L199.093 310.332L178.452 309.026L158.039 302.577L139.153 291.038L123.075 274.79L110.984 254.542L103.878 231.293L102.495 206.294L107.258 180.974L118.228 156.86L135.083 135.488L157.121 118.3L183.283 106.559L212.204 101.253L242.285 103.023L271.778 112.112L298.89 128.324L321.893 151.028L339.227 179.166L349.609 211.309L352.117 245.726L346.267 280.474L332.053 313.513L309.97 342.823L281.003 366.524L246.586 382.999L208.531 391L168.933 389.735L130.058 378.931L94.2098 358.873L63.5944 330.401L40.1869 294.88L25.6046 254.141L21 210.382L26.9768 166.051L43.5368 123.712L70.0607 85.8925L105.325 54.9362L147.558 32.8578L194.522 21.2143L243.639 21L292.126 32.5714L337.155 55.6077" stroke="#fff" strokeWidth="2"/>
            </svg>
            <Bounds
              grid={this.state.grid}
              pageLoaded={this.props.location.pathname !== "/"}
              onPan={this.setTitle}
              spiralWrap={this.spiralWrap}
              spiral={this.spiral}/>
          </div>
          <div id="subPage">
            <TransitionGroup>
              <CSSTransition
                key={this.props.location.key}
                classNames="article"
                appear={true}
                timeout={500}>
                <Switch location={this.props.location}>
                  <Route
                    exact
                    path="/projects/:path"
                    render={({ match }) =>
                    <Article
                      history={this.props.history}
                      onUnmount={this.setTitle}
                      key={match.params.path}
                      uid={match.params.path}
                      imgLoaded={this.setSubPageImgLoaded}
                      minTimeout={this.setSubPageTimeout}/>
                  }/>
                  <Route exact path="/info" render={({ match }) =>
                    <Info
                      onUnmount={this.setTitle}
                      imgLoaded={this.setSubPageImgLoaded}
                      minTimeout={this.setSubPageTimeout}/>
                  }/>
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
      );
    } else if (this.state.notFound) {
      return (
        <div id="container">
          <div id="homepage">
            <motion.h1
              animate={{ opacity: this.state.imgLoaded && this.state.minTimeout ? 0 : 1 }}
              className={this.state.blink ? "blink" : ""}>
                404
            </motion.h1>
          </div>
        </div>
      );
    }
    return (
      <div id="container">
        <div id="homepage">
          <motion.h1
            animate={{ opacity: this.state.imgLoaded && this.state.minTimeout ? 0 : 1 }}
            className={this.state.blink ? "blink" : ""}>
              {this.state.pageTitle}
          </motion.h1>
        </div>
      </div>
    )
  }

}

export default withRouter(App);