import React, { useRef, useState, useEffect } from "react";
import Prismic from 'prismic-javascript';
import ProjectLink from './ProjectLink.js';
import './Bounds.css';

const apiEndpoint = "https://tom-chung-studio.cdn.prismic.io/api/v2";
const client = Prismic.client(apiEndpoint)

export const Bounds = (props) => {
  const Bounds = useRef();
  const [projects, setProjects] = useState();
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    client.query(
      [Prismic.Predicates.at("document.type", "homepage")]
    ).then(response => {
      if (response) {
        setProjects(response.results[0].data.index);
      }
    });

    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, []);

  return projects ? (
    <div
      ref={Bounds}
      className={props.grid ? "bounds grid" : "bounds list"}>
      {projects.map((project, index) => {

        let positions = projects.length;
        let spiralLength = props.spiral.current.getTotalLength();
        let currentPosition = props.spiral.current.getPointAtLength((spiralLength / positions) * index);
        let scaleX = props.spiralWrap.current.getBoundingClientRect().width / props.spiralWrap.current.viewBox.baseVal.width;
        let scaleY = props.spiralWrap.current.getBoundingClientRect().height / props.spiralWrap.current.viewBox.baseVal.height;

        let x = currentPosition.x * scaleX;
        let y = currentPosition.y * scaleY;

        return (
          <ProjectLink
            x={x}
            y={y}
            bounds={Bounds}
            onPan={props.onPan}
            key={project.page.id}
            uid={project.page.uid}
            title={project.page.uid}
            count={projects.length}
            index={index}
            pageLoaded={props.pageLoaded}
            grid={props.grid}
          />
        )
      })}
    </div>
  ) : (
    null
  );
}

export default Bounds;